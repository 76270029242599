import {ReactNode} from 'react';

declare global {
  interface Window {
    gtag: Function;
  }
}

export type PasswordConfigType = {
  length: number;
  use_numbers: boolean;
  use_uppercase: boolean;
  use_lowercase: boolean;
  use_symbols: boolean;
  use_other_symbols: boolean;
}


export type PasswordStatusData = {
  message: string;
  className?: string;
  icon?: ReactNode;
}


export const minLength = 8;
export const maxLength = 128;