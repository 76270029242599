import {createContext, ReactNode, useContext, useState} from 'react';
import {generateNewPassword} from '../utils/generator-v2';
import {PasswordConfigType} from '../types/types';

type PasswordContextType = {
  password: string;
  regeneratePassword: (config: PasswordConfigType) => void;
  passwordConfig: PasswordConfigType;
  updateConfig: (name: string, value: any) => void;
}

const passwordInitData: PasswordContextType = {
  password: '',
  regeneratePassword: () => {
  },
  passwordConfig: {
    length: 12,
    use_numbers: true,
    use_uppercase: true,
    use_lowercase: true,
    use_symbols: true,
    use_other_symbols: false,
  },
  updateConfig: () => {
  },
};

const PasswordContext = createContext<PasswordContextType>(passwordInitData);

export function usePassword() {
  return useContext(PasswordContext);
}

type Props = {
  children: ReactNode;
};

export function PasswordProvider({children}: Props) {
  const [password, setPassword] = useState<string>(passwordInitData.password);
  const [passwordConfig, setPasswordConfig] = useState<PasswordConfigType>(
      passwordInitData.passwordConfig);

  const regeneratePassword = (config: PasswordConfigType) => setPassword(
      generateNewPassword(config));

  const updateConfig = (name: string, value: any) => {
    const newConfig = {
      ...passwordConfig,
      [name]: value,
    };

    setPasswordConfig(newConfig);
    regeneratePassword(newConfig);
  };

  const value: PasswordContextType = {
    password,
    regeneratePassword,
    passwordConfig,
    updateConfig,
  };

  return (
      <>
        <PasswordContext.Provider value={value}>
          {children}
        </PasswordContext.Provider>
      </>
  );
}
