import crypto from 'crypto';
import {maxLength, minLength, PasswordConfigType} from '../types/types';
import {sendGaEvent} from './ga';

const randomChar = (charList: string) => {
  return charList[crypto.randomBytes(1)[0] % charList.length];
};
export const generateNewPassword = (passwordConfig: PasswordConfigType) => {

  const lowercase: string = 'abcdefghijklmnopqrstuvwxyz';
  const uppercase: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers: string = '0123456789';
  const symbols: string = '!@#$%^&*()';
  const specSymbols: string = '~`[];?,';

  let password: string = '';

  let pool: string = '';

  if (passwordConfig.use_numbers) {
    password += randomChar(numbers);
    pool += numbers;
  }

  if (passwordConfig.use_lowercase) {
    password += randomChar(lowercase);
    pool += lowercase;
  }

  if (passwordConfig.use_uppercase) {
    password += randomChar(uppercase);
    pool += uppercase;
  }

  if (passwordConfig.use_symbols) {
    password += randomChar(symbols);
    pool += symbols;
  }

  if (passwordConfig.use_other_symbols) {
    password += randomChar(specSymbols);
    pool += specSymbols;
  }

  const configOptions = [
    passwordConfig.use_other_symbols,
    passwordConfig.use_symbols,
    passwordConfig.use_uppercase,
    passwordConfig.use_lowercase,
    passwordConfig.use_numbers,
  ];

  let mandatoryLength: number = configOptions.filter(item => item).length;

  if (configOptions.every(item => !item)) {
    password += randomChar(lowercase);
    password += randomChar(uppercase);
    password += randomChar(numbers);
    password += randomChar(symbols);
    password += randomChar(specSymbols);

    pool = lowercase + uppercase + numbers + symbols + specSymbols;
    mandatoryLength = configOptions.length;
  }

  if (passwordConfig.length > maxLength) {
    passwordConfig.length = maxLength;
  }

  if (passwordConfig.length < minLength) {
    passwordConfig.length = minLength;
  }

  for (let i = 0; i < passwordConfig.length - mandatoryLength; i++) {
    const index = crypto.randomBytes(1)[0] % pool.length;
    password += pool[index];
  }

  const passwordArray = password.split('');
  for (let i = passwordArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [passwordArray[i], passwordArray[j]] = [passwordArray[j], passwordArray[i]];
  }

  password = passwordArray.join('');

  sendGaEvent('password_v2_generated', passwordConfig);

  return password;
};
