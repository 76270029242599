import '../styles/globals.css';
import type {AppProps} from 'next/app';
import {PasswordProvider} from '../context/PasswordProvider';
import GoogleAnalytics from '../components/GoogleAnalytics';
import Footer from '../components/Footer';
import Header from '../components/Header';

function MyApp({Component, pageProps}: AppProps) {
  return <PasswordProvider>
    <GoogleAnalytics gaId={process.env.GA_ID ?? ''}/>

    <Header/>
    <Component {...pageProps} />
    <Footer/>

  </PasswordProvider>;
}

export default MyApp;
