const Footer = () => {
  return <footer className="p-10 mt-16 bg-black">
    <div className="md:flex justify-between">
      <p className="text-white text-center">
        Copyright © {new Date().getFullYear()}. Random Password Generator
      </p>
      <p className="text-white text-center">All rights reserved</p>
    </div>
  </footer>;
};

export default Footer;