import Script from 'next/script';

type Props = {
  gaId: string
}

const GoogleAnalytics = ({gaId}: Props) => {
  return <>
    <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
        strategy="afterInteractive"
    />
    <Script id="google-analytics" strategy="afterInteractive">
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gaId}', {
        page_path: window.location.pathname,
      });
      `}
    </Script>
  </>;
};

export default GoogleAnalytics;